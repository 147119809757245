.tweet-box {
  display: flex;
  margin-top: 1rem;
}

.text-box {
  border: none !important;
  box-shadow: none !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: end;
}

hr {
  color: var(--bg-color);
  height: 1px;
  border: none;
  background-color: #ccc;
}
