:root {
  --bird-color: #50b7f5;
  --bg-color: #e6ecf0;
}

.app {
  display: flex;
  height: 100vh;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

.feed::-webkit-scrollbar {
  display: none;
}

.feed {
  flex: 0.4;
  min-width: fit-content;
  overflow-y: scroll;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
