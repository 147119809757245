.widgets {
  flex: 0.3;
  border-left: 1px solid var(--bg-color);
  padding-left: 2.5rem;
}

.header-card {
  font-size: 1.2rem;
}

.widget-btn {
  color: black !important;
}

.widget-btn:hover {
  background-color: #d3d3d3 !important;
  border-radius: 30px;
}
