.item {
  display: flex !important;
  align-items: center !important;
  cursor: pointer !important;
  margin-bottom: 5px !important;
}

.item > .MuiSvgIcon-root {
  margin: 15px;
}

.item > h3 {
  color: black !important;
  font-weight: 400 !important;
  font-size: 1.3rem !important;
  margin-right: 20px !important;
  text-transform: inherit !important;
}

.item:hover {
  background-color: #d3d3d3;
  border-radius: 30px;
}
