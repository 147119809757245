.tweet {
  border-top: 1px solid var(--bg-color);
  border-bottom: 1px solid var(--bg-color);
  padding: 2rem;
}

.tweetr-btn {
  cursor: pointer !important;
}

.name-tweet {
  font-weight: 700;
}

.counters {
  margin-top: 12px !important;
  margin-bottom: auto;
  margin-left: 10px;
  font-size: 13px;
}

.tweet-footer {
  margin-top: 2rem;
}

.comment:hover {
  color: lightskyblue;
}

.retweet:hover {
  color: green;
}

.like:hover {
  color: palevioletred;
}

.share:hover {
  color: lightskyblue;
}
