.feed-header {
  min-width: 700px;
  border-top: 1px solid var(--bg-color);
  border-bottom: 1px solid var(--bg-color);
  padding: 2rem;
}

.feed-header > h4 {
  font-weight: 700;
}

.feed-stick {
  position: sticky;
  top: 0;
  z-index: 100;
}

.page-btn {
  color: black !important;
}

.page-btn:hover {
  background-color: #d3d3d3 !important;
  border-radius: 30px;
}

.trigger-btn {
  color: black !important;
}
