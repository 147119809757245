.navigation {
  padding-right: 2.5rem;
  border-right: 1px solid var(--bg-color);
  flex: 0.3;
  min-width: 250px;
  margin-top: 20px;
  height: 100vh;
}

.twitter-btn {
  color: var(--bird-color);
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.tweet-btn {
  background-color: var(--bird-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  height: 50px !important;
  margin-top: 20px !important;
}

.user-btn {
  padding: 20px;
  top: 10rem;
  color: black !important;
  border: none !important;
  font-weight: 400 !important;
  font-size: 1.3rem !important;
  margin-right: 20px !important;
  text-transform: none !important;
  height: 4.5rem;
  align-items: center !important;
}

.user-btn:hover {
  border-radius: 30px;
  background-color: #d3d3d3 !important;
}

.user-btn-name {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.user-btn-foot {
  font-size: 1.1rem;
  padding-bottom: 1rem !important;
}

.logout-btn {
  color: black !important;
  border: none !important;
  font-weight: 400 !important;
  font-size: 1.3rem !important;
  margin-right: 20px !important;
  text-transform: none !important;
  height: 2rem;
  align-items: center !important;
}

.poppy::-webkit-scrollbar {
  display: none !important;
}

.poppy {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
